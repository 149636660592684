<template>
  <div @dragover="dragover" @dragleave="dragleave" @drop="drop" @click="$refs.file.click()">
    <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
    <slot></slot>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  name: "upload-picture-zone",
  props: {
    placeholder: String,
    background: String
  },
  data() {
    return {
      filelist: [],
      uploadPercentage: null
    }
  },
  methods: {
    onChange() {
      let formData = new FormData();
      let files = [...this.$refs.file.files];
      let i = 0;
      if(files.length==0)return;
      _.each(files, file => formData.append('files[' + (i++) + ']', file));
      axios.post('/manager/inspection/uploads/',
          formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: function(progressEvent) {
              this.uploadPercentage = parseInt(Math.round((progressEvent.loaded*100)/progressEvent.total));
              console.log(parseInt(Math.round((progressEvent.loaded*100)/progressEvent.total)));
              this.$emit("progress", this.uploadPercentage);
            }.bind(this)})
          .then(function(response){
            let uploadedFiles = [];
            if (response.status === 200) uploadedFiles = response.data.files;
            this.uploadPercentage = null;
            this.$emit("progress", this.uploadPercentage);
            this.$emit("files", uploadedFiles);
          }.bind(this)).catch(function(){
        this.uploadPercentage = null;
        this.$emit("progress", this.uploadPercentage);
      }.bind(this));
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-blue-200')) {
        event.currentTarget.classList.remove(this.background);
        event.currentTarget.classList.add('bg-blue-200');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add(this.background);
      event.currentTarget.classList.remove('bg-blue-200');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      event.currentTarget.classList.add(this.background);
      event.currentTarget.classList.remove('bg-blue-200');
    }
  },

}
</script>

<style scoped>

</style>